import React from "react";
import { graphql, Link } from "gatsby";
import Layout from "../components/layout";
import { Box, Container, Flex, Heading } from "theme-ui";
import ThemedMarkdown from "../components/themed-markdown";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

type Props = {
  data: {
    markdownRemark: any;
  };
  location: string;
};

const OverviewPage = ({ data, location }: Props) => {
  const { frontmatter } = data.markdownRemark;
  return (
    <Layout
      seo={{
        title: frontmatter.title,
        description: frontmatter.seoDescription,
        pathname: location,
      }}
    >
      <Container my={5}>
        <Heading as="h1" variant="styles.h2" mb={4}>
          {frontmatter.h1}
        </Heading>
        <Flex sx={{ flexDirection: ["column", "column", "row"], mb: 4, flexWrap: "wrap" }}>
          {frontmatter.overviewblock.map((block, i) => (
            <Flex
              key={i}
              sx={{
                flexDirection: "column",
                justifyContent: "space-between",
                mx: 4,
                my: 4,
                minWidth: [undefined, undefined, "300px"],
                maxWidth: [undefined, undefined, "360px"],
              }}
            >
              <Flex sx={{ flexDirection: "column", flex: "1 1 0" }}>
                <Heading as="h2" variant="styles.h4" mb={2}>
                  {block.targetname}
                </Heading>
                <Link to={block.linktarget}>
                  <GatsbyImage image={getImage(block.image)} alt={block.alttext} />
                </Link>
                <ThemedMarkdown html={block.markdown} />
                <Box>
                  <Link to={block.linktarget}>Mehr Erfahren</Link>
                </Box>
              </Flex>
            </Flex>
          ))}
        </Flex>
      </Container>
    </Layout>
  );
};

export default OverviewPage;

export const pageQuery = graphql`
  query OverviewPageById($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      frontmatter {
        seoDescription
        title
        h1
        overviewblock {
          image {
            childImageSharp {
              gatsbyImageData(
                quality: 92
                layout: CONSTRAINED
                aspectRatio: 1
                placeholder: BLURRED
              )
            }
          }
          alttext
          markdown
          targetname
          linktarget
        }
      }
    }
  }
`;
